import BigNumber from 'bignumber.js'
import get from 'lodash.get'
import React from 'react'

import { getAmmConfig } from '../config/amm'
import { RECALL_DELAY } from '../config/settings'
import { isDgNativeTokenAddress } from '../helpers/wrappedTokenHelpers'
import {
  Address,
  Currency,
  FormatDateChartType,
  MarketType,
  NetworkConfigV3,
  TokenV3,
  TrendingTokenV3,
} from '../model'
import { NetworkConfigState } from '../reducers/networksConfig'
import { store } from '../services/reduxService'
import { getTokenAddress } from './addressUtils'

const ACCOUNTS_KEY = 'accounts'

interface Response {
  reason: string
  validationErrors: { field: string; reason: string }[]
  values: { message: string }
}

export async function retryWrapper<T>(
  task: () => Promise<T>,
  attempts: number,
  canThrow: boolean,
  interval?: number
): Promise<T | undefined | string> {
  let counter = 0
  let lastError = ''
  let result = null
  while (counter < attempts) {
    try {
      result = await task()
      counter = attempts
    } catch (error) {
      const { message } = error as { message: string | undefined }
      await sleep(interval || RECALL_DELAY)
      counter = counter + 1
      lastError = message || ''
    }
  }

  if (result && !lastError) {
    return result
  }

  if (lastError && canThrow) {
    throw new Error(lastError)
  }

  if (lastError && !canThrow) {
    return lastError
  }
}

export const timestampFromUnixToWeb = (timestamp: number): number => timestamp * 1000
export const timestampFromWebToUnix = (timestamp?: number): number | undefined =>
  timestamp ? Math.round(timestamp / 1000) : undefined

export const setHoursBeforeMidnight = (date: number): number =>
  new Date(date).setHours(23, 59, 59, 0)

export const getHoursUTCBeforeMidnight = (timestamp?: number): number => {
  const date = timestamp ? new Date(timestamp) : new Date()
  return date.setUTCHours(0, 0, 0, 0) / 1000 - 1
}

export const setHoursAfterMidnight = (date: number): number => new Date(date).setHours(0, 0, 0, 0)

export const setTokenToLocalStorage = (currentToken: TokenV3, account?: string | null): void => {
  localStorage.setItem('anonimToken', JSON.stringify(currentToken))
  if (!account) {
    return
  }
  const accountsList = JSON.parse(localStorage.getItem(ACCOUNTS_KEY) || '{}')
  accountsList[account] = accountsList[account]
    ? { ...accountsList[account], memoryToken: currentToken }
    : { memoryToken: currentToken }
  localStorage.setItem('accounts', JSON.stringify(accountsList))
}

export const getTokenIdFromLocalStorage = (account?: Address | null): string | null => {
  if (!account) {
    return JSON.parse(localStorage.getItem('anonimToken') || '{}')?.id || null
  }
  const accountsList = JSON.parse(localStorage.getItem(ACCOUNTS_KEY) || '{}')
  return accountsList && accountsList[account] && accountsList[account].memoryToken
    ? accountsList[account].memoryToken?.id
    : null
}

const getNativeTokensSymbols = (): string[] => {
  return store
    .getState()
    .networksConfig?.data?.filter((x) => !!x.native_token)
    .map((x) => x.native_token.symbols[0])
}

export const getNativeTokenSymbolByNetwork = (network: string): string => {
  return store
    .getState()
    .networksConfig?.data?.filter((x) => network === x.name)
    .map((x) => x.native_token.symbols[0])[0]
}

export const replaceWrapperTokenToToken = (symbol = ''): string => {
  // Some native tokens (e.g. CELO) are not wrapped
  if (!symbol.startsWith('W')) {
    return symbol
  }
  // with W (wrapped): WETH, WAVAX etc.
  const nativeTokenSymbols = getNativeTokensSymbols()
  const index = nativeTokenSymbols.indexOf(symbol)
  return index === -1 ? symbol : nativeTokenSymbols[index].substring(1) // removes first "W" letter
}

export const replaceWrapperTokenName = (name = '', symbol = ''): string => {
  const nativeTokenSymbols = getNativeTokensSymbols()
  if (nativeTokenSymbols.includes(symbol)) {
    if (name.startsWith('Wrapped')) {
      return name.substring('Wrapped'.length).trim()
    } else {
      return name
    }
  }
  return name
}

export const numberWithCommas = (x?: number | string): string | typeof NaN => {
  if (!x) {
    return NaN
  }
  const parts = x.toString().split('.')

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts[1] && parts[1] === '' ? `${parts[0]}.` : parts.join('.')
}

export const isTitleNaN = (title?: number | string): string => {
  return !title && String(title) === 'NaN' ? '0' : String(title)
}

export function getHeightTableSidebar(element: HTMLElement | null): number {
  const header = document.querySelector('.header')
  const dashboard = document.querySelector('.dashboard')

  const dashboardHeight = (dashboard && dashboard.getBoundingClientRect().height) || 0
  const headerHeight = (header && header.getBoundingClientRect().height) || 0
  const bodyHeight = headerHeight + dashboardHeight

  const windowHeight = window.innerHeight
  const sidebarHeight =
    windowHeight > bodyHeight ? windowHeight - headerHeight : bodyHeight - headerHeight
  const topSidebar = document.querySelector('.chart-sidebar')
  const titleTable = element?.querySelector('.title-table-sidebar')
  const headerTable = element?.querySelector('.thead-table-sidebar')

  const topSidebarHeight = (topSidebar && topSidebar.getBoundingClientRect().height) || 0
  const titleTableHeight = (titleTable && titleTable.getBoundingClientRect().height) || 0
  const headerTableHeight = (headerTable && headerTable.getBoundingClientRect().height) || 0
  const padding = 40 + 16
  return sidebarHeight - topSidebarHeight - titleTableHeight - headerTableHeight - padding
}

export function calculateMobile(sidebarNumber: number): number {
  let counter = 0
  const topPart = document.querySelectorAll('.chart-sidebar')[0]
  const header = document.querySelectorAll('.header')[0]
  const additional = sidebarNumber === 1 ? 8 : 0

  if (topPart && header) {
    counter +=
      window.innerHeight - topPart?.clientHeight - header?.clientHeight - additional - 64 - 82 - 24
  } else {
    counter += 360
  }
  return counter
}

export const getMarketDisplayName = (token?: TokenV3): string => {
  return getMarketDisplayNameBySymbols(token)
}

export const getMarketDisplayNameTrending = (token?: TrendingTokenV3): string => {
  return getMarketDisplayNameBySymbols(token)
}

export const getMarketDisplayNameV2 = (token?: TokenV3): string => {
  return getMarketDisplayNameBySymbols(token)
}

export const getMarketDisplayNameBySymbols = (
  token?: TokenV3 | { symbol: string } | { symbols: string[] }
): string => {
  if (!token) {
    // fallback
    return replaceWrapperTokenToToken()
  }
  const isTokenV3Type = Object.prototype.hasOwnProperty.call(token, 'marketType')
  const hasSymbolProp = Object.prototype.hasOwnProperty.call(token, 'symbol')
  const hasSymbolsProp = Object.prototype.hasOwnProperty.call(token, 'symbols')
  if (token && isTokenV3Type) {
    const tokenV3 = token as TokenV3
    // regular ERC20 token
    if (tokenV3.marketType === MarketType.token) {
      if (isDgNativeTokenAddress(tokenV3.address)) {
        return tokenV3.symbols[0]
      }
      return replaceWrapperTokenToToken(tokenV3.symbols[0])
    }
    // LP Token
    if (tokenV3.marketType === MarketType.lp) {
      const ammConfig = getAmmConfig(tokenV3.AMM || '')
      const ammDisplayName = ammConfig.displayName
      const LpSymbols = tokenV3.symbols
        .map((symbol: string) => replaceWrapperTokenToToken(symbol))
        .join('/')
      return `${ammDisplayName} ${LpSymbols} LP`
    }
    return replaceWrapperTokenToToken(tokenV3.symbols[0])
  }
  // token with one symbol
  if (token && !isTokenV3Type && hasSymbolProp) {
    const tokenWithSymbol = token as { symbol: string }
    return replaceWrapperTokenToToken(tokenWithSymbol.symbol)
  }
  // token with a few symbols
  if (token && !isTokenV3Type && hasSymbolsProp) {
    const tokenWithSymbols = token as { symbols: string[] }
    return tokenWithSymbols.symbols.map((symbol) => replaceWrapperTokenToToken(symbol)).join('/')
  }
  // fallback
  return replaceWrapperTokenToToken()
}

export const deleteDuplicatedItem = <T>(array: T[]): T[] => {
  return array.filter((elem, index, self) => {
    return index === self.indexOf(elem)
  })
}

export const sleep = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

/**
 * Generic retry function of async operations depending on errors
 * @param task the call that should be made
 * @param shouldRetry a test that can check if a retry attempt should be done depending on the error
 * @param interval how much time between calls
 * @param attempts how many attempts
 */
export async function asyncRetry<T>(
  task: () => Promise<T>,
  shouldRetry: (error: unknown) => boolean,
  interval: number,
  attempts: number
): Promise<T> {
  try {
    return await task()
  } catch (err) {
    if (shouldRetry(err) && attempts > 0) {
      await sleep(interval)
      return asyncRetry(task, shouldRetry, interval, attempts - 1)
    } else {
      throw err
    }
  }
}

export const isNativeTokenForNetwork = (token: TokenV3): boolean => {
  const networkConfig = getNetworkConfig(token.network)
  if (isDgNativeTokenAddress(token.address)) {
    return false
  }
  return getTokenAddress(token).toLowerCase() === networkConfig.native_token.address?.toLowerCase()
}

export const grabErrors = (respJson: Response): string => {
  let statusText = respJson.reason + ':'
  if (respJson.validationErrors?.length > 0) {
    respJson.validationErrors.forEach((item: { field: string; reason: string }) => {
      statusText += ' ' + item.field + ' - ' + item.reason + ';'
    })
  } else {
    statusText += ' ' + respJson.values.message + ';'
  }

  return statusText
}

export const replaceToNumbersAndDots = (value: string): string => {
  return value.trim().replace(/[^.0-9]/g, '')
}

export const deleteDuplicatedDots = (value: string): string => {
  const digitalValue = replaceToNumbersAndDots(value)

  if (digitalValue === '') {
    return ''
  }

  const splitted = digitalValue.split('.')
  const intPart = splitted[0] || '0'

  if (splitted.length > 1) {
    const fractialPart = splitted.slice(1).join('')
    return `${intPart}.${fractialPart}`
  }

  return `${intPart}`
}

export const getRoundedValue = (value: string, digit = 10): string => {
  const splitted = value.split('.')
  let digits = new Array(digit).fill('0').join('')

  if (splitted[1]) {
    digits = splitted[1].slice(0, digit)
  }

  return `${splitted[0]}.${digits}`
}

export const getExplorerTokenUrl = (tokenNetwork: string, tokenAddress: string): string => {
  const networkConfig = getNetworkConfig(tokenNetwork)
  const blockExplorerUrl = networkConfig.block_explorer.url
  const tokenPath = networkConfig.block_explorer.token_path
  return `${blockExplorerUrl}${tokenPath}/${tokenAddress}`
}

export const pickValueBasedOnCurrency = (
  selectedCurrency: string,
  stableValue?: number | null,
  nativeValue?: number | null
): number => (selectedCurrency === 'USD' ? stableValue || 0 : nativeValue || 0)

export const idAndNetworkMatch = (
  value1: { id?: string; network?: string },
  value2: { id?: string; network?: string }
): boolean => {
  return value1.id?.split('-')[0] === value2.id?.split('-')[0] && value1.network === value2.network
}

export const sortTokens = (tokens: TokenV3[], propsSort: string): TokenV3[] => {
  return tokens.sort((a: TokenV3, b: TokenV3) =>
    get(a, propsSort, 0) > get(b, propsSort, 0) ? -1 : 1
  )
}

export const changeFormatDateChart = (
  date?: number,
  type?: FormatDateChartType,
  isFullDate?: boolean,
  period?: string
): string => {
  const webTimestamp = date ? timestampFromUnixToWeb(date) : new Date().getTime()
  if (period === 'day') {
    return `${
      isFullDate
        ? `${new Date(webTimestamp).toLocaleString('en-US', { day: 'numeric', month: type })},`
        : ``
    } ${new Date(webTimestamp).toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
    })} ${isFullDate ? `(UTC)` : ``}`
  }
  return new Date(webTimestamp).toLocaleString('en-US', { day: 'numeric', month: type })
}

export const hashCode = (s: string): number => {
  let h = 0
  for (let i = 0; i < s.length; i++) {
    h = (Math.imul(31, h) + s.charCodeAt(i)) | 0
  }

  return h
}

export const getNetworkConfig = (network: string): NetworkConfigV3 => {
  if (!network) {
    throw new Error(`network is not defined`)
  }
  const { networksConfig } = store.getState()
  const networkConfig = networksConfig.data.find((x) => x.name === network)

  if (!networkConfig) {
    throw new Error(`Settings for network ${network} wasn't found`)
  }
  return networkConfig
}

export const getUnWrappedNativeTokenSymbol = (network: string): string => {
  const networkConfig = getNetworkConfig(network)
  return networkConfig.native_token.symbols[0].replace(/^W/i, '')
}

export const getGasBufferForNetwork = (network: string): BigNumber => {
  const networkConfig = getNetworkConfig(network)
  const nativeTokenDecimals = networkConfig.native_token.decimals
  return new BigNumber(networkConfig.gas_buffer).div(10 ** nativeTokenDecimals)
}

export const getNetworkConfigByChainId = (chainId?: number): NetworkConfigV3 | undefined => {
  if (!chainId) {
    return
  }
  const { networksConfig } = store.getState()
  const networkConfig = networksConfig.data.find((x) => x.id === chainId)
  if (!networkConfig) {
    console.error(`Settings for network ${chainId} wasn't found`)
    return
  }
  return networkConfig
}

export const getNetworkNameByChainId = ({
  chainId,
  networksConfigData,
}: {
  chainId?: number
  networksConfigData: NetworkConfigV3[]
}): string | undefined => {
  if (!chainId) {
    return
  }
  const networkConfig = networksConfigData.find((x) => x.id === chainId)
  if (!networkConfig) {
    return
  }

  return networkConfig.name
}

export const getInlineNetworkStyle = (networkConfig: NetworkConfigV3): React.CSSProperties => {
  return {
    '--dexguru-network-color': networkConfig?.color || 'currentColor',
  } as React.CSSProperties
}

export const getInlineNetworkIconStyle = (
  tokenNetwork?: string,
  networkConfig?: NetworkConfigV3
): React.CSSProperties => {
  return {
    '--dexguru-network-content': tokenNetwork !== 'eth' ? '""' : 'none',
    '--dexguru-network-color':
      tokenNetwork !== 'eth' ? networkConfig?.color || 'currentColor' : 'transparent',
  } as React.CSSProperties
}

export const getAvailableNetworks = (networksConfig: NetworkConfigState): string[] => {
  return networksConfig.data.map((x) => x.name)
}

export const getCurrencyName = (network: string, selectedCurrency: Currency): string =>
  selectedCurrency === 'USD' ? 'USD' : getUnWrappedNativeTokenSymbol(network)

type error0xValidationField = {
  code: number
  field: string
  reason: string
}

type error0x = {
  code: number
  reason: string
  source?: string
  validationErrors?: error0xValidationField[]
  values?: { message: string }
}

type error0xWrapper = {
  detail: error0x[]
}

const error0xParser = (error: error0x): string => {
  if (error.validationErrors) {
    return `${error.validationErrors
      .map(({ field, reason }) => `${field}-${reason}`)
      .join(',')} :: Code ${error.code} : Reason ${error.reason}: Source ${error.source};`
  }

  if (error.values && error.values.message && error.values.message) {
    return `${error.values.message} :: Code ${error.code} : Reason ${error.reason}: Source ${error.source}`
  }

  if (error.reason) {
    return `Code ${error.code} / Reason ${error.reason}: Source ${error.source}`
  }

  return ''
}

export const error0xToString = (
  error: error0xWrapper | error0x,
  defaultErrorMessage: string
): string => {
  let message

  if ('detail' in error) {
    message = error.detail.map(error0xParser).join(',')
  } else {
    message = error0xParser(error)
  }

  return message || defaultErrorMessage
}

export const shortNetworkDescriptor = (network: NetworkConfigV3): string =>
  network.description.split(' ')[0]

/**
 * Return first word of network description from chain endpoint
 * @param network network name
 * @return Etherium | BSC | Polygon and etc. Returns generic 'network' if configuration not found
 *
 **/
export const getNetworkDescriptionName = (network?: string): string => {
  if (!network) {
    return 'network'
  }
  try {
    const networkConfig = getNetworkConfig(network)
    return shortNetworkDescriptor(networkConfig)
  } catch (error) {
    console.error("Wasn't able to find network description for network", network)
    return 'network'
  }
}

export const getChainIdByNetwork = (network?: string): number | undefined => {
  if (!network) {
    return
  }
  try {
    const networkConfig = getNetworkConfig(network)
    return networkConfig.id
  } catch (error) {
    console.error("Wasn't able to find network description for network", network)
    return
  }
}

/**
 * @description
 * Takes an Array<V>, and a grouping function,
 * and returns a Map of the array grouped by the grouping function.
 *
 * @param array An array of type V.
 * @param keyGetter A Function that takes the the Array type V as an input, and returns a value of type K.
 *                  K is generally intended to be a property key of V.
 *
 * @returns Map of the array grouped by the grouping function.
 */
export function arrayGroupBy<K, V>(array: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> {
  const map = new Map<K, Array<V>>()
  array.forEach((item) => {
    const key = keyGetter(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })
  return map
}

export const removeAllServiceWorkers = (): void => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (const registration of registrations) {
        registration.unregister()
      }
    })
  }
}
